<template>
    <BT-Blade-Items
        addBladeName="standing-order"
        bladeName="standing-orders-schedule"
        :bladesData="bladesData"
        :canSearch="false"
        canSearchLocal
        customURL="/Schedule/Schedule"
        isSingle
        :loadingMsg="loadingMsg"
        navigation="suppliers"
        :onPullSuccessAsync="res => { return res.scheduleItems }"
        :params="{}"
        showList
        title="Schedule"
        :actualUsedHeight="175"
        :defaultBladeWidth="350"
        @sourceChanged="setData">
        <template v-slot:toolbar>
            <v-toolbar-title key="3">{{ agreement != null ? agreement.buyer.companyName : '' }} Schedule</v-toolbar-title>
        </template>
        <template v-slot:listItem="{ item }">
            <v-list-item-content>
                <v-list-item-title v-if="item.orderBy == null && item.purchaseOrderID != null">(On Order)</v-list-item-title>
                <v-list-item-title v-else>{{ item.orderBy | toDayShortDate }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.deliverBy != null"><v-icon left small>mdi-truck</v-icon>{{ item.deliverBy | toDayShortDate }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn v-if="item.purchaseOrderID != null" small icon @click="openCustomerOrder(item)" title="Order Exists">
                    <v-icon small>mdi-receipt</v-icon>
                </v-btn>
                <v-row v-else dense>
                    <v-btn :disabled="item.isCancelled" small icon @click="openCustomerOrder(item)" title="New Order">
                        <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn :disabled="item.isCancelled" small icon @click="repeatLastOrder(item)" title="Repeat Last Order">
                        <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn :disabled="item.standingOrderID == null || item.isCancelled" small icon @click="instantiateOrder(item)" title="Cancel">
                        <v-icon small>mdi-receipt-text-plus</v-icon>
                    </v-btn>
                    
                    <v-btn v-if="item.isCancelled" small icon @click="undoCancelInstance(item)" title="Undo cancellation">
                        <v-icon small>mdi-undo</v-icon>
                    </v-btn>
                    <v-btn v-else :disabled="item.standingOrderID == null" class="error--text" small icon @click="cancelInstance(item)" title="Cancel">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-row>
            </v-list-item-action>
        </template>

        <template v-slot:actions>
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Items>
</template>

<script>
import { addCancellation, removeCancellation } from '~helpers';

export default {
    name: 'Standing-Orders-Schedule-Blade',
    components: {
        
    },
    data: function() {
        return {
            agreement: null,
            agreementID: null,
            customerID: null,
            loadingMsg: null,
            msg: null
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        async cancelInstance(sItem) {
            try {
                this.loadingMsg = 'Cancelling';
                var standingOrder = await this.$BlitzIt.store.get('standing-customer-orders', sItem.standingOrderID);
                if (standingOrder != null) {
                    standingOrder.adjustments = addCancellation(standingOrder.adjustments, sItem.orderBy);
                    var res = await this.$BlitzIt.store.patch('standing-customer-orders', standingOrder);
                    standingOrder.rowVersion = res.rowVersion;
                    sItem.isCancelled = true;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async instantiateOrder(item) {
            this.bladesData.openBlade({ 
                bladeName: 'customer-order',
                data: {
                    id: 'new',
                    standingOrderID: item.standingOrderID,
                    agreementID: this.agreementID,
                    customerID: this.customerID,
                    dueDate: item.deliverBy
                } 
            });
        },
        openCustomerOrder(sItem) {
            this.bladesData.openBlade({
                bladeName: 'customer-order',
                data: {
                    id: sItem.purchaseOrderID || 'new',
                    agreementID: this.agreementID,
                    customerID: this.customerID,
                    orderBy: sItem.orderBy,
                    dueDate: sItem.deliverBy
                }
            })
        },
        async repeatLastOrder(item) {
            this.bladesData.openBlade({ 
                bladeName: 'customer-order',
                data: {
                    id: 'new',
                    repeatLast: true,
                    agreementID: this.agreementID,
                    customerID: this.customerID,
                    orderBy: item.orderBy,
                    dueDate: item.deliverBy
                } 
            });
        },
        setData(bladeData) {
            if (bladeData != null && bladeData.data != null) {
                this.agreement = bladeData.data.agreement;
                this.agreementID = bladeData.data.agreementID;
                this.customerID = bladeData.data.customerID;
            }
        },
        async undoCancelInstance(sItem) {
            try {
                this.loadingMsg = 'Undoing Cancellation';
                var standingOrder = await this.$BlitzIt.store.get('standing-customer-orders', sItem.standingOrderID);
                if (standingOrder != null) {
                    standingOrder.adjustments = removeCancellation(standingOrder.adjustments, sItem.orderBy);
                    var res = await this.$BlitzIt.store.patch('standing-customer-orders', standingOrder);
                    standingOrder.rowVersion = res.rowVersion;
                    sItem.isCancelled = false;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>